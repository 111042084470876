import React from 'react';
import './Qui_somme_nous.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Mathis from '../../assets/images/Mathis.png';
import Erwan from '../../assets/images/Erwan.jpg';
import Thierry from '../../assets/images/Thierry.jpg';

const QuiSommesNousPage: React.FC = () => {
  const teamMembers = [
    {
      name: 'Mathis Blanc',
      title: 'Chef d\'équipe (Co-fondateur)',
      description: `Mathis Blanc est notre chef d'équipe dynamique. En tant que manager, il supervise l'ensemble de l'équipe et assure la gestion efficace des projets. Avec son leadership inspirant, il guide notre équipe vers l'excellence et la réussite.`,
      imgSrc: Mathis
    },
    {
      name: 'Erwan Bauer',
      title: 'Responsable du produit (Co-fondateur)',
      description: `Erwan Bauer est à l'origine de l'idée innovante derrière le produit SAPÉ. En tant que responsable du produit, il apporte une vision claire et dirige le développement du produit tout en restant à l'écoute des retours clients.`,
      imgSrc: Erwan
    },
    {
      name: 'Thierry Segarra',
      title: 'Chef de l\'équipe développement (Co-fondateur)',
      description: `Thierry Segarra est notre expert technique en chef. En tant que chef de l'équipe développement, il est chargé de trouver des solutions techniques innovantes et de les mettre en œuvre avec précision.`,
      imgSrc: Thierry
    },
  ];

  return (
    <div className="page-container">
    <Header />
    <main className="about-main">
      <h2 className="about-title">QUI SOMMES NOUS ?</h2>
      {teamMembers.map((member, index) => (
        <section
          key={index}
          className={`team-member ${index % 2 === 0 ? 'light' : 'dark'} ${index % 2 === 1 ? 'reverse' : ''}`}
        >
          <div className="team-content">
            <h2>{member.name} - {member.title}</h2>
            <p>{member.description}</p>
          </div>
          <div className="team-image">
            <img src={member.imgSrc} alt={member.name} />
          </div>
        </section>
      ))}
    </main>
    <Footer />
  </div>
  );
};

export default QuiSommesNousPage;
