import React, { useEffect, useState } from 'react';
import './Header.css';
import SAPE from '../../assets/images/SAPE_logo.jpg';

const Header: React.FC = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${scrollPosition > 100 ? 'header--scrolled' : ''}`}>
      <div className="header__content">
        <div className="logo"><a href="/"><img src={SAPE} alt="Sapé logo" className="logo" /></a></div>
        <nav>
          <ul>
            <li><a href="/particuliers">Particuliers</a></li>
            <li><a href="/entreprise">Entreprise</a></li>
            <li><a href="/actualites">Actualités</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
        <button className="login-button">SE CONNECTER</button>
      </div>
    </header>
  );
};

export default Header;