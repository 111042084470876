import React from 'react';
import './Mentions_legales.css';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

const LegalNotice = () => {
  return (
    <div className="legal-page">
      <Header />
      <div className="blue-block">
        <h1 className="blue-block-title"></h1>
      </div>
      <main className="legal-content">
        <h1>Mentions Légales de SAPÉ</h1>
        <p className="intro-text">
          Les mentions légales de SAPÉ. Tout savoir sur règles, droit et responsabilités de SAPÉ.
        </p>
        <section className="legal-section">
          <h2>Données Personnelles</h2>
          <p>
            La totalité des données communiquées par les internautes sont strictement confidentielles. En aucun cas elles ne
            pourront être transmises à quiconque. Conformément à la loi "Informatique et Libertés", les internautes disposent
            des droits d'accès, de rectification et d'opposition de l'ensemble des données nominatives, et seront en mesure
            de les exercer par courrier postal ou encore depuis notre page contact.
          </p>
        </section>
        <section className="legal-section">
          <h2>Droits d'Auteurs et Liens Hypertextes</h2>
          <p>
            L'ensemble des droits de reproduction des textes, documents photographiques, iconographies et logos sont réservés
            sur sapé.app. Par ailleurs, le bon fonctionnement et la validité des liens hypertextes menant vers d'autres sites
            Internet n'engagent en rien la responsabilité de SAPÉ.
          </p>
        </section>
        <section className="legal-section">
          <h2>Compétences</h2>
          <p>
            Ces conditions Générales d'Utilisation sont régies par la loi française, et tous litiges relatifs à leur
            interprétation ou à leur exécution seront soumis au Tribunal de Commerce.
          </p>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default LegalNotice;
