import React from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import IndexVideo from '../../assets/video/presentation.mp4';
import Tel from '../../assets/images/Tel sape.png';

import './Accueil.css';

const Accueil = () => {
  return (
    <div className="accueil-container">
      <Header />
      <div className="video-section">
        <video className="full-screen-video" autoPlay loop muted>
          <source src={IndexVideo} type="video/mp4" />
        </video>
      </div>
      <div className="presentation-section">
        <div className="presentation-content">
          <h2>Présentation du produit</h2>
          <p>
            SAPÉ est bien plus qu'une simple application de garde-robe virtuelle. Créer par l'entreprise Evoltly, c'est une solution complète qui révolutionne la manière dont vous gérez votre style et vos vêtements au quotidien.
          </p>
          <p>
            Notre plateforme intuitive vous permet de créer et de gérer votre garde-robe en ligne, vous offrant un accès instantané à toutes vos tenues, où que vous soyez. Fini les moments de panique devant votre placard, SAPÉ vous permet de planifier vos tenues avec facilité, que ce soit pour une journée ordinaire ou une occasion spéciale.
          </p>
        </div>
        <div className="phone-mockup">
          <img src={Tel}/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Accueil;