import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Accueil from "./pages/Accueil/Accueil";
import Actualites from "./pages/Actualites/Actualites";
import Admin from "./pages/Admin/Admin";
import CGU from "./pages/CGU/CGU";
import Contact from "./pages/Contact/Contact";
import FAQ from "./pages/FAQ/FAQ";
import Mentions_legales from "./pages/Mentions_legales/Mentions_legales";
import Mon_compte from "./pages/Mon_compte/Mon_compte";
import Politique from "./pages/Politique/Politique";
import Qui_somme_nous from "./pages/Qui_somme_nous/Qui_somme_nous";
import Signup_login from "./pages/Signup_login/Signup_login";

function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Accueil />}></Route>
          <Route path="/actualites" element={<Actualites />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/cgu" element={<CGU />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="/mentions-legales" element={<Mentions_legales />}></Route>
          <Route path="/mon-compte" element={<Mon_compte />}></Route>
          <Route path="/politique" element={<Politique />}></Route>
          <Route path="/qui-sommes-nous" element={<Qui_somme_nous />}></Route>
          <Route path="/signup-login" element={<Signup_login />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
